import React from "react"
import styled from "styled-components"

import Banner from "$components/Banner"
import Container from "$components/Container"
import Feature from "$components/Feature"
import Footer from "$components/Footer"
import Header from "$components/Header"
import SEO from "$components/SEO"

import bannerAbout from "./assets/banner-about.jpg"
import iconBalance from "./assets/icon-balance.svg"
import iconHandshake from "./assets/icon-handshake.svg"
import iconTeam from "./assets/icon-team.svg"
import partnerDell from "./assets/partner-dell.png"
import partnerKyocera from "./assets/partner-kyocera.jpg"
import partnerMicrosoft from "./assets/partner-microsoft.jpg"
import partnerSophos from "./assets/partner-sophos.png"
import partnerTPG from "./assets/partner-tpg.png"
import partnerVeeam from "./assets/partner-veeam.png"
import partnerWatchguard from "./assets/partner-watchguard.jpg"

const BannerContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const CentredP = styled.p`
  text-align: center;
`

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    flex-direction: column;
    align-items: center;
  }
`

const PartnerLogo = styled.img`
  height: 100px;
  margin: 0 20px;

  a:first-child & {
    margin-left: 0;
  }

  a:last-child & {
    margin-right: 0;
  }

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    margin: 10px 0;
  }
`

const featureData = [
  {
    heading: "People First",
    imgAlt: "Team icon",
    imgSrc: iconTeam,
    text:
      "Don't call a helpdesk. With LOTUS as your IT partner, call and speak directly to your dedicated Technology Adviser.",
  },
  {
    heading: "No-Nonsense Pricing",
    imgAlt: "Balance icon",
    imgSrc: iconBalance,
    text:
      "No unreasonable quotes or unexpected charges. We find the solutions that fit your needs at your price point.",
  },
  {
    heading: "All-in-One Service",
    imgAlt: "Handshake icon",
    imgSrc: iconHandshake,
    text:
      "LOTUS will take care of all your technology needs, including dealing with service providers so you don't have to.",
  },
]

const title = "About Us | Lotus Network Solutions"
const description =
  "Established in 2011 by a team of IT professionals, Lotus Network Solutions has expertise in providing IT products, services and support."

export default () => (
  <>
    <SEO description={description} title={title} />
    <Header />
    <main>
      <Banner backgroundPosition="50%" bannerImage={bannerAbout} height="400">
        <BannerContainer>
          <h1>About</h1>
        </BannerContainer>
      </Banner>
      <section>
        <Container>
          <h2 id="our-story">Our Story</h2>
          <CentredP>
            Established in 2011, Lotus Network Solutions manages the information
            technology needs of accounting and legal practices across Australia.
            With over 30 years experience serving clients throughout Australia
            and a particular understanding of rural and remote challenges, we
            are well equipped to your practice with city smart solutions backed
            by country values and loyalty.
          </CentredP>
          <CentredP>
            Entering the computer industry in 1988, founder Aiman Farrag started
            his career at Qubie assembling XT and 286 systems for the accounting
            industry using CeeData Systems software. Aiman worked at CeeData
            while completing a computing degree, from which he graduated in
            1995. He has subsequently worked in a variety of roles and
            industries, returning to the finance and legal sectors in the
            mid-2000s.
          </CentredP>
          <CentredP>
            We have seen that over time, the IT industry has made it
            increasingly difficult for businesses to interact with providers and
            manufacturers. LOTUS navigates manufacturers' support systems on
            your behalf so that your dedicated Technology Adviser is the only
            contact you need.
          </CentredP>
          <CentredP>
            Our wealth of knowledge and experience allows us to deliver
            personalised, comprehensive and reliable information technology to
            practices across Australia.
          </CentredP>
        </Container>
      </section>
      <section>
        <h2 id="company-values">Company Values</h2>
        <Feature data={featureData} />
      </section>
      <section>
        <h2 id="vendor-certification">Vendor Certification</h2>
        <CentredP>
          Lotus Network Solutions is proud to work with the following partners:
        </CentredP>
        <ImageContainer>
          <a
            href="https://www.dell.com/en-au"
            rel="noopener noreferrer"
            target="_blank"
          >
            <PartnerLogo alt="Dell Partner logo" src={partnerDell} />
          </a>
          <a
            href="https://www.kyoceradocumentsolutions.com.au/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <PartnerLogo alt="Kyocera logo" src={partnerKyocera} />
          </a>
          <a
            href="https://www.microsoft.com/en-au"
            rel="noopener noreferrer"
            target="_blank"
          >
            <PartnerLogo alt="Microsoft Partner logo" src={partnerMicrosoft} />
          </a>
          <a
            href="https://www.sophos.com/en-us.aspx"
            rel="noopener noreferrer"
            target="_blank"
          >
            <PartnerLogo alt="Sophos Silver Partner logo" src={partnerSophos} />
          </a>
          <a
            href="https://www.tpg.com.au/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <PartnerLogo alt="TPG logo" src={partnerTPG} />
          </a>
          <a
            href="https://www.veeam.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <PartnerLogo alt="Veeam Partner logo" src={partnerVeeam} />
          </a>
          <a
            href="https://www.watchguard.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <PartnerLogo alt="Watchguard logo" src={partnerWatchguard} />
          </a>
        </ImageContainer>
      </section>
    </main>
    <Footer />
  </>
)

// icon acknowledgement

// <div>Icons made by <a href="https://www.flaticon.com/authors/those-icons" title="Those Icons">Those Icons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
