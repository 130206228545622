import { Link } from "gatsby"
import { rgba } from "polished"
import React from "react"
import styled from "styled-components"

import Container from "./Container"

const FeatureContainer = styled(Container)`
  display: flex;
  justify-content: space-around;
  margin: 2rem auto;

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    display: block;
  }
`

const FeatureIcon = styled.img`
  height: 80px;
`

const FeatureItem = styled.div`
  align-items: center;

  display: flex;
  flex: 0 0 25%;
  flex-direction: column;
  padding: 20px;

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    margin: 1rem 0;
  }
`

const FeatureItemLink = styled(FeatureItem).attrs({ as: Link })`
  border: 1px solid ${props => props.theme.alto};
  color: ${props => props.theme.nero};
  text-decoration: none;
  transition: box-shadow 0.3s, color 0.3s;

  :hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    color: ${props => rgba(props.theme.nero, 0.8)};
  }
`

const FeatureText = styled.p`
  text-align: center;
`

const Feature = ({ data }) => {
  return (
    <FeatureContainer>
      {data.map(({ heading, imgAlt, imgSrc, linkTo, text }) => {
        const FeatureItemType = linkTo ? FeatureItemLink : FeatureItem
        return (
          <FeatureItemType to={linkTo} key={heading}>
            <FeatureIcon alt={imgAlt} src={imgSrc} />
            <h3>{heading}</h3>
            <FeatureText>{text}</FeatureText>
          </FeatureItemType>
        )
      })}
    </FeatureContainer>
  )
}

export default Feature
